
import { defineComponent } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { WalletMultiButton, useWallet } from "@/plugins/solana-wallets-vue";
import { Transaction, Connection } from "@solana/web3.js";
import DefaultPopup from "@/components/molecules/DefaultPopup.vue";

export default defineComponent({
  name: "MintSection",
  data() {
    const schema = yup.object().shape({
      mintCount: yup.mixed().notRequired(),
    });

    return {
      message: "",
      schema,
      wallets: [],
      diskonect: true,
      auth: useWallet(),
      mintCount: null,
      isModalOpen: false,
      data: {
        mint: "Mint",
        notNFTs: "Not enough NFTs",
      },
      signature: "",
      signatureNew: "",
    };
  },
  watch: {
    auth: {
      deep: true,
      handler() {
        const condition = useWallet();
        if (condition.wallet.value) {
          this.diskonect = false;
        } else this.diskonect = true;
        if (this.diskonect === false && condition.connecting.value) {
          if (condition.wallet.value) {
            // this.handleSubmit()
          }
        }
      },
      immediate: true,
    },
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    WalletMultiButton,
    DefaultPopup,
  },
  mounted() {
    const condition = useWallet();
    if (condition.wallet.value) {
      if (condition.connecting.value == false) {
        this.diskonect = false;
      }
    }
    // condition.disconnect();
  },
  methods: {
    handleCloseModal() {
      this.isModalOpen = false;
    },
    async handleSubmit() {
      const { publicKey, sendTransaction } = useWallet();
      this.$store
        .dispatch("getNFTs/mint", {
          account: publicKey.value?.toString(),
          count: 1,
        })
        .then(async (response) => {
          let json = response;
          let transaction = Transaction.from(
            Buffer.from(json.data.transaction, "base64")
          );
          let connection: Connection;
          this.signatureNew = await sendTransaction(transaction, connection);
          this.$router.push({
            path: `/dashboard/mint/${this.signatureNew}`,
            query: { id: `${json.data.nft_id}` },
          });
        })
        .catch((error) => {
          if (error.response.data.error === "no_available_nfts") {
            this.isModalOpen = true;
          }
        });
    },
  },
});

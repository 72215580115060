import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ade8378"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mint-section" }
const _hoisted_2 = { class: "mint-section__title" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  key: 0,
  class: "form-group"
}
const _hoisted_5 = { class: "form-button" }
const _hoisted_6 = { class: "mint-section__disconnect-button" }
const _hoisted_7 = { class: "mint-section__popup-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!
  const _component_WalletMultiButton = _resolveComponent("WalletMultiButton")!
  const _component_DefaultPopup = _resolveComponent("DefaultPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(this.data.mint), 1),
    _createVNode(_component_Form, {
      onSubmit: _ctx.handleSubmit,
      "validation-schema": _ctx.schema,
      class: "mint-section__form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Field, {
            name: "mintCount",
            type: "text",
            placeholder: "1",
            class: "form-control field",
            modelValue: this.mintCount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.mintCount) = $event)),
            disabled: true
          }, null, 8, ["modelValue"]),
          _createVNode(_component_ErrorMessage, {
            name: "mintCount",
            class: "error-feedback"
          })
        ]),
        (this.diskonect == false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(this.data.mint), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"]),
    _createElementVNode("div", _hoisted_6, [
      (this.diskonect)
        ? (_openBlock(), _createBlock(_component_WalletMultiButton, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isModalOpen)
      ? (_openBlock(), _createBlock(_component_DefaultPopup, {
          key: 0,
          "on-close": _ctx.handleCloseModal
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, _toDisplayString(this.data.notNFTs), 1)
          ]),
          _: 1
        }, 8, ["on-close"]))
      : _createCommentVNode("", true)
  ]))
}